<template>
  <section class="app-ecommerce-details" v-if="this.brandData">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Brand Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px;">
      <b-col md="6">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
    
              
              <b-img
                @click="imgIndex = 0"
                :src="brandData.logo"
                class="rounded"
                size="120"
                variant="light"
              />
            </div>
          </div>
          <h2><span style="color: #0d6efd;"></span></h2>
          <h3></h3>
          <h6 class="text-muted"></h6>
          <b-badge class="profile-badge" variant="light-primary">
            <span
              v-for="(translation, index) in brandData.translation" :key="index"
              style="font-size: 25px; margin: auto;"
            >
              {{ translation.name }}
              <span
                v-if="
                  brandData.translation.length > 1 &&
                  index < brandData.translation.length - 1
                "
                >-</span
              >
            </span>
          </b-badge>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
          <hr class="mb-2" />
          <h5>
            Status:
            <b-badge variant="primary">{{ brandData.active }}</b-badge>
          </h5>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-modal
            id="modal-deactive"
            cancel-variant="outline-secondary"
            @ok="deActiveBrand(brandData.id)"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Deactivate Brand"
          >
            <b-form>
              <b-form-checkbox
                id="checkbox-1"
                v-model="deactive"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                Force Deactivate
              </b-form-checkbox>
            </b-form>
          </b-modal>
          <b-modal
            id="modal-delete"
            cancel-variant="outline-secondary"
            @ok="deleteData(brandData.id)"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Delete Brand"
          >
            <b-form>
              <b-form-checkbox
                id="checkbox-2"
                v-model="deletedBrand"
                name="checkbox-2"
                value="1"
                unchecked-value="0"
              >
                Force Delete
              </b-form-checkbox>
            </b-form>
          </b-modal>
          <b-modal
            id="modal-active"
            cancel-variant="outline-secondary"
            @ok="activeBrand(brandData.id)"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Activate Brand"
          >
            <b-form
              style="max-height: 500px; overflow-x: hidden; overflow-y: auto;"
            >
              <b-form-group
                label="Using options array:"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  stacked
                  v-model="restoreSelected.items"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-1"
                ></b-form-checkbox-group>
              </b-form-group>
              <infinite-loading
                @infinite="getAllVariantsInSystem"
              ></infinite-loading>
            </b-form>
          </b-modal>
          <div v-show="delCat == true" class="">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/Update-Brand/' + brandData.id }"
              variant="primary mr-1 mt-1"
            >
              Update
            </b-button>
            <b-button
              block
              v-b-modal.modal-active
              v-if="authPermissions.includes('update')"
              v-show="brandData.active == false"
              @click="restoreSelected.items = []"
              variant="info"
            >
              Activate
            </b-button>
            <b-button
              block
              v-b-modal.modal-deactive
              v-if="authPermissions.includes('update')"
              v-show="brandData.active == true"
              variant="info"
            >
              Deactivate
            </b-button>
            <b-button
              v-b-modal.modal-delete
              block
              v-if="authPermissions.includes('delete')"
              variant="outline-danger"
              class=""
            >
              Delete
            </b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              v-show="delCat == false"
              block
              v-b-modal.modal-restore
              v-if="authPermissions.includes('update')"
              @click="restoreSelected.items = []"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Restore
            </b-button>
            <b-modal
              id="modal-restore"
              cancel-variant="outline-secondary"
              @ok="restoreBrand(brandData.id)"
              ok-title="Ok"
              cancel-title="Close"
              centered
              title="Restore Brand"
            >
              <b-form
                style="max-height: 500px; overflow-x: hidden; overflow-y: auto;"
              >
                <b-form-group
                  label="Using options array:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    stacked
                    v-model="restoreSelected.items"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <infinite-loading
                  @infinite="getAllVariantsInSystem"
                ></infinite-loading>
              </b-form>
            </b-modal>
          </div>
          <hr />
          <div
            v-if="delCat"
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ brandData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ brandData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ brandData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ brandData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Cover">
          <div class="d-flex justify-content-center">
      
            
            <b-img
              @click="imgIndex1 = 0"
              rounded
              :src="brandData.cover"
              alt="Cover"
              class=""
              style="max-width: 100%; height: auto;"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Description">
      <!-- Brand Translations -->
      <b-card-body>
        <b-tabs align="center" class="text-center">
          <b-tab
            v-for="(translation , index) in brandData.translation"
            :title="translation.locale == 'en' ? 'English' : 'Arabic'" :key="index"
          >
            <b-card-text v-html="translation.description"></b-card-text>
            <b-button
              v-if="authPermissions.includes('update') && delCat"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="warning"
              class="btn-cart"
              :to="{
                name: 'Brand-Update-Trans',
                params: { id: brandData.id, language: translation.locale },
              }"
            >
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span>Update {{ translation.locale }}</span>
            </b-button>
            <b-button
              v-if="
                authPermissions.includes('delete') &&
                translation.locale != 'en' &&
                translation.locale != 'ar' &&
                delCat
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
              class="btn-cart ml-1"
              @click="deleteTranslation(translation.id)"
            >
              <feather-icon icon="DeleteIcon" class="mr-50" />
              <span>Delete {{ translation.locale }}</span>
            </b-button>
          </b-tab>
          <!-- add new translation -->
          <b-button
            v-if="authPermissions.includes('create')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            :to="{ path: '/brand/add/description/' + brandData.id }"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add New Translation</span>
          </b-button>
        </b-tabs>
      </b-card-body>
    </b-card>
    <b-card>
      <b-tabs>
        <b-tab title="Products">
          <products-table
            :link="'brands/' + id + '/products-pagi/'"
          ></products-table>
        </b-tab>
        <b-tab title="Items">
          <items-table
            :link="'brands/' + id + '/items-guide-pagi/'"
          ></items-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>

<script>
import ProductsTable from '@/Components/PrdouctsTable'
import ItemsTable from '@/Components/ItemsTable'

export default { 
  components: {
    
    ProductsTable,
    ItemsTable,
  },
  data() {
    return {
      restoreSelected: {
        items: [],
      },
      id: 0,
      deactive: '0',
      BrandID: null,
      deletedBrand: '0',
      brandData: {},
      errors_back: [],
      showDismissibleAlert: false,
      authPermissions: null,
      imgIndex: null,
      imgIndex1: null,
      iconArr: [],
      coverArr: [],
      delCat: true,
      options: [],
      currentPage: 0,
    }
  },
  mounted() {
    // rquest brand data
  },
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('brands/' + this.id)
        .then((result) => {
          this.brandData = result.data.data
          this.iconArr.push(this.brandData.logo)
          this.coverArr.push(this.brandData.cover)
          if (this.brandData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    activeBrand(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to activate brand.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .post('brands/' + id + '/active-brand', this.restoreSelected)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deActiveBrand(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactivate brand.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('brands/' + id + '/deactive-brand/' + this.deactive)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    getAllVariantsInSystem($state) {
      this.currentPage += 1
      axios
        .get('brands/' + this.id + '/items-pagi/10?page=' + this.currentPage)
        .then((result) => {
          const variants = result.data.data.data
          this.loadMore = false
          if (variants.length > 0) {
            for (let index in variants) {
              if (variants[index].translation != null) {
                this.options.push({
                  text: variants[index].translation.name,
                  value: variants[index].id.toString(),
                })
              } else {
                this.options.push({
                  text: 'undefinded',
                  value: variants[index].id.toString(),
                })
              }
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete function to delete brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete brand.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('brands/' + id + '/archive-brand/' + this.deletedBrand)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreBrand(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore brand.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .post('brands/' + id + '/restore-brand', this.restoreSelected)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete translation.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('brands-translations/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
